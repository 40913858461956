import React from 'react';

import Logo from '../assets/svg/logo';

import { Box, Flex } from '@chakra-ui/react';

// markup
const IndexPage = () => {
  return (
    <section>
      <title>Ezi Graphics</title>
      <Flex justify="center" direction="column" h="100vh" align="center">
        <Box as="span" w="368.46px">
          <Logo />
        </Box>
      </Flex>
    </section>
  );
};

export default IndexPage;
