import React from 'react';

const logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 368.52 95.79">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_2-2" data-name="Layer 2">
          <g id="Group_15" data-name="Group 15">
            <path
              id="Path_15"
              data-name="Path 15"
              d="M40.71,73.48h-19a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2h19a2,2,0,0,1,2,2h0a2,2,0,0,1-2,2Z"
              fill="#0095ff"
            />
            <path
              id="Path_16"
              data-name="Path 16"
              d="M40.71,79.49h-19a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2h19a2,2,0,0,1,2,2h0a2,2,0,0,1-2,2Z"
              fill="#0095ff"
            />
            <path
              id="Path_17"
              data-name="Path 17"
              d="M33,20H32v28.5a3.3,3.3,0,1,1-1.53,0V20H29.36L16.12,51.44a6.56,6.56,0,0,0,.2,5.51l5.37,10.53h19L46.06,57a6.52,6.52,0,0,0,.2-5.51Z"
              fill="#fff"
            />
            <path
              id="Path_18"
              data-name="Path 18"
              d="M24.19,81.38a7,7,0,1,0,14,0Z"
              fill="#36f"
            />
            <line
              id="Line_9"
              data-name="Line 9"
              x1="31.19"
              y1="15.61"
              x2="31.19"
              y2="0.89"
              stroke-width="0.89"
              stroke="#272525"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
            />
            <g id="Group_10" data-name="Group 10">
              <path
                id="Path_19"
                data-name="Path 19"
                d="M31.19,16.49a.88.88,0,0,1-.89-.88h0V.89a.89.89,0,1,1,1.78,0V15.61a.88.88,0,0,1-.89.88Z"
                fill="#ffca5f"
              />
            </g>
            <g id="Group_11" data-name="Group 11">
              <path
                id="Path_20"
                data-name="Path 20"
                d="M41.33,22.68a.89.89,0,0,1-.89-.89.93.93,0,0,1,.14-.49l8-12.36a.89.89,0,0,1,1.5,1l-8,12.36A.9.9,0,0,1,41.33,22.68Z"
                fill="#fa0"
              />
            </g>
            <g id="Group_12" data-name="Group 12">
              <path
                id="Path_21"
                data-name="Path 21"
                d="M48.06,30.77a.89.89,0,0,1-.36-1.7L61.11,23a.89.89,0,0,1,.73,1.63l-13.42,6A.74.74,0,0,1,48.06,30.77Z"
                fill="#fa0"
              />
            </g>
            <g id="Group_13" data-name="Group 13">
              <path
                id="Path_22"
                data-name="Path 22"
                d="M14.33,30.77a1,1,0,0,1-.37-.08L.55,24.64A.89.89,0,0,1,1.23,23l.05,0L14.7,29.07a.89.89,0,0,1-.37,1.7Z"
                fill="#fa0"
              />
            </g>
            <g id="Group_14" data-name="Group 14">
              <path
                id="Path_23"
                data-name="Path 23"
                d="M19.33,20a.88.88,0,0,1-.75-.4l-8-12.36a.89.89,0,0,1,1.5-1h0l8,12.36a.89.89,0,0,1-.26,1.23A.92.92,0,0,1,19.33,20Z"
                fill="#fa0"
              />
            </g>
          </g>
          <path
            d="M101.4,69.14C98,78.4,90.48,80.31,84.52,80.31c-8.76,0-13.13-6.28-13.13-12.82a14.06,14.06,0,0,1,11.48-14c-4.32-2.35-6.28-6-6.28-9.64,0-5.78,5.07-11.49,14.08-11.49,5.9,0,11.68,2.35,11.68,8.5,0,3.37-1.21,5.84-4.44,5.84A4.56,4.56,0,0,1,94.29,45c1.59-.44,2.79-3.61,2.79-5.2,0-1-.13-4.7-4.19-4.7a7.59,7.59,0,0,0-7.74,7.81c0,3.68,2.41,7.8,8.38,10.47-8.06,3.3-11,8.37-11,12.75,0,4.63,3.36,8.44,7.42,8.44,4.89,0,7.68-2,10-6.09Z"
            fill="#fff"
          />
          <path
            d="M114.28,74.22c0-3,2-3.87,4.06-3.87,1.77,0,3,1.65,3,3.68,0,3.23-2.35,5.9-7.42,5.9H96.83l16.69-29.06c-1.46-.07-3.18-.13-4.64-.13-2,0-3.49.26-3.49,1.4.58,0,1.08.76,1.08,1.84,0,3-2,3.8-4,3.8-1.78,0-3-1.64-3-3.68,0-3.23,2.35-5.9,7.43-5.9H124L107.3,77.26c1.46.07,3.11.13,4.63.13,2,0,3.49-.25,3.49-1.4C114.85,76,114.28,75.3,114.28,74.22Z"
            fill="#fff"
          />
          <path
            d="M133.38,69.78a8.82,8.82,0,0,0-.32,2.15c0,1.33.51,2.35,2.28,2.35a5.08,5.08,0,0,0,1.65-.19c-1.77,5.46-4.44,5.84-6.6,5.84-4.5,0-6.47-3-6.47-7a14.07,14.07,0,0,1,.38-3.17l4.57-21.58h9.07Zm1.33-35a5.09,5.09,0,0,1,5.07,5.08,5,5,0,0,1-5.07,5,5,5,0,0,1-5-5A5,5,0,0,1,134.71,34.75Z"
            fill="#fff"
          />
          <path
            d="M188.32,53.41l-5.2,23.85c-2.1,9.71-5.84,15.8-16.24,15.8-6,0-8.82-4.38-8.82-8.76s2.73-8.69,7.74-8.69c2.54,0,6.22.19,6.22,4.25a10,10,0,0,1-.39,2.54c-1.58-1.33-2.53-1.65-3.8-1.65-2.92,0-4.44,2.48-4.44,4.82s1.33,4.32,4,4.32c2.86,0,4.89-1.27,6.35-7.81l2.54-11.35A19.56,19.56,0,0,1,168.53,73c-10.35,0-13.65-7.49-13.65-16.24,0-12.12,8.32-24.43,22.46-24.43,5.91,0,11.81,2.35,11.81,8.5,0,3.37-1.21,5.84-4.45,5.84A6,6,0,0,1,180.33,45c1.58-.44,2.79-3.61,2.79-5.2,0-1-.32-4.51-4.38-4.51-7,0-14.08,11.87-14.08,22,0,5.64,2.09,10.47,7.36,10.47,2.34,0,4.06-.58,5.39-2.35l2.73-12Z"
            fill="#fff"
          />
          <path
            d="M204.18,48.2l-.69,3.18c2.28-1.78,4.82-3.18,7.48-3.18,2.86,0,4.57,1.65,4.57,4.06a4.85,4.85,0,0,1-4.76,5c-3.55,0-1.77-4.18-3.68-4.18s-2.92.69-4.38,2l-5.26,24.87h-9.14l6.73-31.73Z"
            fill="#fff"
          />
          <path
            d="M243.46,48.2l-4.57,21.58a11.43,11.43,0,0,0-.25,2c0,1.4.63,2.41,3.17,2.41s5.84-3.55,8.37-5.58h2.16c-2.92,2.92-10.09,11.36-15,11.36-4.12,0-6.91-1.53-7.74-4.83-1.9,2.61-4.75,5.21-8.63,5.21-6.09,0-7.86-4.32-7.86-8.06,0-10.91,3.93-24,16.62-24a6.89,6.89,0,0,1,4.25,1.58l.38-1.65ZM221.63,70.54c0,3.8,2.1,4.5,3.68,4.5s2.86-1.39,4.06-3.17a15.55,15.55,0,0,1,.38-2.09L233.31,53a4.73,4.73,0,0,0-3.11-1.14C225.44,51.82,221.63,61.91,221.63,70.54Z"
            fill="#fff"
          />
          <path
            d="M259.26,48.2,259,49.41a9.63,9.63,0,0,1,4.44-1.14c6.6,0,9.71,2.34,9.71,8.88a28.51,28.51,0,0,1-.38,4.44c-2,13.64-7.42,18.27-13.89,18.27a11,11,0,0,1-6-1.9l-3.3,15.54L240,95.79,250.12,48.2Zm5.39,14.15a33.78,33.78,0,0,0,.83-6.41c0-3.8-1.53-4.56-3.56-4.56a6.46,6.46,0,0,0-3.61,1.33L253.74,74a6.39,6.39,0,0,0,4.12,1.14C261,75.17,262.56,72.44,264.65,62.35Z"
            fill="#fff"
          />
          <path
            d="M300.18,69.78a8.8,8.8,0,0,0-.32,2c0,1.39.7,2.34,3.18,2.34s5.83-3.55,8.37-5.58h2.16c-2.92,2.92-10.09,11.36-15,11.36s-7.87-2.16-7.87-6.92a15.6,15.6,0,0,1,.38-3.23l2.42-11.42a8.36,8.36,0,0,0,.19-1.65c0-2.29-1.21-3.75-2.92-3.75a6.41,6.41,0,0,0-3.94,1.34l-5.45,25.63H272.2l9.2-43.15,9.39-1.27-3.43,16.06c2.61-1.84,5.52-3.37,8.25-3.37,3.43,0,7.3,3.68,7.3,7.94a6.05,6.05,0,0,1-.13,1.52Z"
            fill="#fff"
          />
          <path
            d="M320.87,48.2,316.3,69.78a11.51,11.51,0,0,0-.26,2c0,1.4.64,2.41,3.18,2.41s5.83-3.55,8.37-5.58h2.16c-2.92,2.92-10.09,11.36-15,11.36s-7.93-2.16-7.93-6.92a16.07,16.07,0,0,1,.38-3.23l4.57-21.58ZM318,34.75A5.09,5.09,0,0,1,323,39.83a5,5,0,0,1-5.07,5,5,5,0,0,1-5-5A5,5,0,0,1,318,34.75Z"
            fill="#fff"
          />
          <path
            d="M341.93,52.71c0-1.52-.95-2.16-2.35-2.16-5.33,0-8.75,10.34-8.75,17.2,0,3.74.76,6.78,4.63,6.78,4.51,0,8.12-2.66,11-6h2.16C345.61,72,342.19,79.93,331,79.93c-5.27,0-8.7-2.92-8.7-10.41,0-9.26,4-21.51,16.31-21.51,3.43,0,7,.89,7,4.38a6.36,6.36,0,0,1-.32,2.1,3,3,0,0,0-1.58-.39,6.86,6.86,0,0,0-2,.39A6.65,6.65,0,0,0,341.93,52.71Z"
            fill="#fff"
          />
          <path
            d="M363.88,47.06V48c0,10.47,4.64,13.51,4.64,20.94,0,5.9-3.75,11-12.31,11-7.3,0-10.85-2.1-10.85-8.31,0-5,2.28-7.94,4.82-8.13,2.16-4.12,3.43-9.7,4.51-14.52Zm-13,24.3a5.13,5.13,0,0,1-2.29-.57c0,3.74,2,5.39,5.4,5.39,2.15,0,5.26-2.66,5.26-7.55,0-3.93-2.85-7.29-3-15.22-1,3.11-1.9,7.17-3.3,10.34,1.52.51,2.1,1.65,2.1,3.55A4.12,4.12,0,0,1,350.88,71.36Z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default logo;
